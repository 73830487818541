@use 'sass:math';

.c-print-brand {
  display: none;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
}

/* To print onto A4, landscape: 297mm X 210mm */
$page-width: 297mm;
$page-height: 210mm;
$page-margin: 10mm;
/* The number of lines in the table. (That would be the number of rows if 
there was only one line in each row. */
$no-of-lines: 52;
$heading-height: 10mm;
/* Some correction is sometimes needed to adjust the height of lines to make 
sure the whole table fits on the page. */
$correction: 0.1mm;

$line-height: math.div(
  $page-height - 2 * $page-margin - $heading-height,
  ($no-of-lines - $correction)
);

@media print {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  @page :left {
    margin-left: 2cm;
  }

  @page :right {
    margin-left: 2cm;
  }

  @page {
    size: A4 landscape;
  }

  @page:right {
    @bottom-left {
      margin: 10pt 0 30pt 0;
      border-top: 0.25pt solid #666;
      content: 'Express Transfer';
      font-size: 9pt;
      color: #333;
    }
  }

  h1 {
    /* just using the space alloted to the heading  */
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  table {
    border-collapse: collapse;
  }

  /* This can also be used instead of "table-layout: fixed;" above for 
    the text-overflow to function with percentage widths on the td or th */
  tr > * {
    max-width: 0;
  }

  td,
  th {
  }

  /* Let us add some colors for debugging to delimitate the 
    page area with the margins removed */
  body {
    position: relative;
  }

  .bg-light {
    background-color: white !important;
  }

  h1 {
    page-break-before: always;
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  h3,
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 12px;
  }

  body {
    padding: 0 !important;
    margin: 0 !important;
    -webkit-print-color-adjust: exact;
  }

  body > * {
    background-color: white !important;
  }

  .px-3 {
    padding: 0 !important;
    margin: 0 !important;
  }

  .card-header {
    background-color: white !important;
    padding: 0 !important;
  }

  .btn,
  .pagination,
  form,
  .header,
  .filterCard,
  .breadcrumb,
  .sidebar,
  .footer {
    display: none;
  }
  .badge {
    background-color: transparent !important;
    color: #000 !important;
    border: none !important;
    padding: inherit;
    font-size: inherit;
  }
  .wrapper,
  .sidebar:not(.sidebar-end) ~ *,
  html:not([dir='rtl']) .wrapper {
    width: 100%;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 0 !important;
  }

  .row > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 0 !important;
  }

  .c-print-brand {
    display: block;
    margin: 0 auto;
    background-color: #fff;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  table td {
    background-color: white !important;
    padding: 2px;
  }

  table,
  figure {
    page-break-inside: avoid;
  }

  .noprint {
    display: none;
  }
}
